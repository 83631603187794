import Map from 'ol/Map.js';
import Overlay from 'ol/Overlay.js';
import { Group as LayerGroup } from 'ol/layer.js';
import View from 'ol/View.js';
import XYZ from 'ol/source/XYZ.js';
import OSM from 'ol/source/OSM.js';
import { fromLonLat } from 'ol/proj.js';
import { Vector as VectorSource } from 'ol/source.js';
import WKB from 'ol/format/WKB.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
// import * as data from './wkb.json';
import { Fill, Stroke, Style } from 'ol/style.js';
import { Select } from 'ol/interaction.js';

const adminUUID = 'b4e87165-4270-4e2b-b831-353fc8d285fc';
const attributions =
  '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
const closer = document.getElementById('popup-closer');
const isAdmin = new URLSearchParams(window.location.search).get('user') == adminUUID ? true : false;
const columns = [
  {
    label: 'Codice',
    key: 'code'
  },
  {
    label: 'Nome',
    key: 'name'
  },
  {
    label: 'Cabina primaria',
    key: 'cod_ac'
  },
  {
    label: 'Tipologia',
    key: 'type'
  },
  {
    label: 'Destinazione',
    key: 'destination'
  },
  {
    label: 'Ubicazione',
    key: 'address'
  },
  {
    label: 'Coordinate',
    key: 'latlon'
  }
];

if (!isAdmin) {
  columns.splice(0, 2);
  columns.pop();
  document.getElementById('municipality-input').placeholder = 'Cerca località';
}

const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const environment = {
  api: "https://dev-cergis.darwintech.it/services/api"
};
const mapBoxKey = 'pk.eyJ1IjoiaXJqbmUiLCJhIjoiY2xza2swcHlkMDN3ajJtbmFrMTY5ajQ1byJ9.PMNExFUDDdz9-OLZqRZPYw';
const mapTilerKey = 'gXUazHv4zqI1jPSEybEt';

const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

const podTable = document.getElementById("pod-table");
const podTablePagination = document.getElementById("pod-table-pagination");

var data = null;
var features = [];
var filteredPods = [];
var inp = document.getElementById("municipality-input");
var map = null;
var municipalities_code = null;
var municipalities = null;
var municipality = null;
var podFilter = null;
var pods = null;
var primary_cabins = null;
var selectedMunicipality = null;
var table = {
  pageIndex: 0,
  pageSize: 25,
  page: 1
};
var view = null;

/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

initMap();
showOrHidePage('show');
getPods();
autocomplete(inp, municipalities_code);

/**
 * Create the map.
 */
function initMap() {
  view = new View({
    center: fromLonLat([14, 42]),
    zoom: 6,
    maxZoom: 18
  });

  map = new Map({
    layers: [
      new TileLayer({
        source: new OSM({
          tileSize: [512, 512]
        })
        // new XYZ({
        //   attributions: attributions,
        //   url: 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=' + mapTilerKey,
        //   tileSize: [512, 512],
        // }),
      })
    ],
    overlays: [overlay],
    target: 'map',
    view: view
  });
}

/**
 * Modifica la visibilità di un singolo layer
 */
function bindInputs(layerid, layer) {
  const visibilityInput = $(layerid + ' input.visible');
  visibilityInput.on('change', function () {
    layer.setVisible(this.checked);
  });
  visibilityInput.prop('checked', layer.getVisible());
}

/**
 * Modifica la visibilità di un gruppo di layer
 */
function setupLayerVisibility(id, group) {
  group.getLayers().forEach(function (layer, i) {
    const layerid = id + i;
    bindInputs(layerid, layer);
    if (layer instanceof LayerGroup) {
      setupLayerVisibility(layerid, layer);
    }
  });
}

/**
 * Generatore di palette per layer
 */

function generateColorPalette(counter) {
  const palette = [];

  for (let i = 0; i < counter; i++) {
    const color = generateRandomColor(palette, counter);
    palette.push(color);
  }

  return palette;
}

/**
 * Generatore di colori randomici
 */
function generateRandomColor(existingColors, counter) {
  const getRandomValue = () => Math.floor(Math.random() * 256);

  var index = 0;
  while (true) {
    const rgb = `rgba(${getRandomValue()}, ${getRandomValue()}, ${getRandomValue()}, 0.3)`;

    // Check if the color is distinct from existing colors
    if (!existingColors.some(existingColor => areColorsSimilar(existingColor, rgb, counter)) || index > 10) {
      return rgb;
    }

    index++;
  }
}

/**
 * Verifica se i colori della palette sono simili
 */

function areColorsSimilar(color1, color2, counter) {
  const getColorValues = (color) => color.match(/\d+/g)?.map(Number) || [];

  const values1 = getColorValues(color1);
  const values2 = getColorValues(color2);

  if (values1.length !== 5 || values2.length !== 5) {
    return false;
  }

  const threshold = 256 / counter; // Adjust this threshold as needed (ex: 20)


  for (let i = 0; i < 3; i++) {
    if (Math.abs(values1[i] - values2[i]) < threshold) {
      return true; // Colors are similar
    }
  }

  return false; // Colors are not similar
}

/**
 * Crea i WKB a partire dal JSON di partenza
 */
function createWKBFromData() {
  if (map.getLayers().getArray().length > 1) {
    map.unset('layers');
    map.setLayers([
      new TileLayer({
        source: new OSM({
          tileSize: [512, 512]
        })
        // new XYZ({
        //   attributions: attributions,
        //   url: 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=' + mapTilerKey,
        //   tileSize: [512, 512],
        // }),
      })
    ])
    map.render();
  }

  var format = new WKB();

  features.push(format.readFeature(data.municipality.wkb_geometry, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  }));

  municipalities = new VectorLayer({
    source: new VectorSource({
      features: features,
    }),
    style: [new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2
      })
    })]
  });

  municipality = features[0];

  features = [];

  const colorPalette = generateColorPalette(data.primary_cabins.length);
  data.primary_cabins.forEach(cabin => {
    var format = new WKB();

    var feature = format.readFeature(cabin.geom, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });

    feature.setProperties({
      primary_cabin: cabin.cod_ac,
      rag_soc: cabin.rag_soc,
      entity: 'cabin'
    });

    features.push(feature);

    features.forEach((cabin, i) => {
      cabin.setStyle(new Style({
        fill: new Fill({
          color: colorPalette[i],
        }),
        stroke: new Stroke({
          color: colorPalette[i].replace('0.3', '0.6'),
          width: 1
        })
      }));
    });

  });

  primary_cabins = new VectorLayer({
    source: new VectorSource({
      features: features,
    })
  });

  features = [];

  data.pods.forEach(pod => {
    var format = new WKB();

    var feature = format.readFeature(pod.wkb_geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });

    feature.setProperties({
      primary_cabin: pod.cod_ac,
      address: pod.address,
      destination: pod.destination,
      type: pod.type,
      entity: 'pod'
    });

    if (isAdmin) feature.setProperties({
      code: pod.code,
      name: pod.name,
      latlon: pod.latlon
    });

    features.push(feature);

    pod.feature = feature;
  });

  pods = new VectorLayer({
    source: new VectorSource({
      features: features
    })
  });

  // ** aggiunge i tre layer alla mappa
  map.addLayer(new LayerGroup({
    layers: pods.getSource().getFeatures().length > 0 ? [primary_cabins, municipalities, pods] : [primary_cabins, municipalities],
  }));

  if (municipality.getGeometry()) {
    centerOnGeometry(municipality, 'polygon');
  }
}

function centerOnGeometry(feature, entity, pod = null) {
  const size = map.getSize();

  if (entity == 'polygon') {
    const polygon = feature.getGeometry().getExtent();
    map.getView().fit(polygon, { padding: [size[0] / 2, size[1] / 2, size[0] / 2, size[1] / 2] });
    map.getView().setZoom(12);
    // map.getView().setCenter(feature.getGeometry().getCoordinates());
  }
  else {
    const point = feature.getGeometry();
    overlay.setPosition(point.getCoordinates());
    map.getView().centerOn(point.getCoordinates(), size, [size[0] / 3, size[1] / 2]);
    map.getView().setZoom(15);
    map.getView().setCenter(point.getCoordinates());

    var html = '';

    if (isAdmin) html = `<h4>${pod.code}: ${pod.name}</h4>`;
    html += `<p><b>Cabina primaria:</b> ${pod.cod_ac}
    <br><b>Tipologia:</b> ${pod.type}
    <br><b>Ubicazione:</b> ${pod.address}
    <br><b>Destinazione:</b> ${pod.destination}`;

    if (isAdmin) html += `<br><b>Coordinate:</b> ${pod.latlon}</p>`;

    content.innerHTML = html;

    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
}

/**
 * Generazione delle righe della tabella a partire dal JSON
 */
function generatePODTable(filtered = data.pods) {
  if (filtered.length > 0) {
    var header = '<thead><tr>';
    var body = '<tbody id="table-body"><tr>';
    var pagination = '';

    columns.forEach(column => {
      header += `<th>${column.label}</th>`;
    });

    filtered.slice(table.pageIndex, table.pageSize + table.pageIndex).forEach(pod => {
      columns.forEach(column => {
        body += `<td id="${pod.code}-${[column.key]}">${pod[column.key] ?? 'N/A'}</td> `;
      });
      body += '</tr><tr>';
    });

    if (table.page > 2) pagination += `<span class="pagination-link" id="page-first"><<</span>`;
    if (table.page > 1) pagination += `<span class="pagination-link" id="page-prev"><</span>`;
    pagination += `<span class="pagination-link">${table.page}</span>`;

    if (table.page < Math.ceil(filtered.length / table.pageSize)) pagination += `<span class="pagination-link" id="page-next">></span>`;
    if (table.page < Math.ceil(filtered.length / table.pageSize) - 1) pagination += `<span class="pagination-link" id="page-last">>></span>`;


    header += '</tr></thead>';
    body += '</tr></tbody>';

    podTable.innerHTML = `<table>` + header + body + `</table>`;
    podTablePagination.innerHTML = pagination;
  } else {
    podTable.innerHTML = '<i>Nessun risultato trovato</i>';
    podTablePagination.innerHTML = '';
  }

  document.getElementById("counter").innerHTML = filtered.length;
}

function changePage(page) {
  if (page == 'first') {
    table.page = 1;
    table.pageIndex = 0;
  } else if (page == 'prev') {
    table.page--;
    table.pageIndex -= table.pageSize;
  } else if (page == 'next') {
    table.page++;
    table.pageIndex += table.pageSize;
  } else if (page == 'last') {
    table.page = Math.ceil(data.pods.length / table.pageSize);
    table.pageIndex = ((table.page - 1) * table.pageSize);
  }

  generatePODTable();
}

function filterPodTable() {
  setTimeout(filter(), 1000);

  function filter() {
    table.page = 1;
    table.pageIndex = 0;

    podFilter = document.getElementById("podFilter-input").value;
    filteredPods = data.pods;

    if (podFilter != '' && podFilter != ' ' && podFilter != null) filteredPods = data.pods.filter(pod => { return pod.code.toLowerCase().includes(podFilter.toLowerCase()) || pod.name.toLowerCase().includes(podFilter.toLowerCase()) || pod.cod_ac.toLowerCase().includes(podFilter.toLowerCase()) });
    generatePODTable(filteredPods);
  }
}

window.filterPodTable = filterPodTable;

/**
 * API che recupera i comuni
 */
function getMunicipalities(code, a, b, val) {
  // Definire l'URL di destinazione
  var url = `${environment.api}/municipalities/${code}`;

  // Eseguire la chiamata HTTP in GET utilizzando fetch
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Errore nella richiesta');
      }
      return response.json();
    })
    .then(data => {
      municipalities_code = data;
      var arr = data;

      // * autocomplete

      for (var i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (arr[i].comune && arr[i].comune.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          b.setAttribute("ogc_fid", arr[i].ogc_fid);
          /*make the matching letters bold:*/
          b.innerHTML = "<strong>" + arr[i].comune.substr(0, val.length) + "</strong>";
          b.innerHTML += arr[i].comune.substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i].comune + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName("input")[0].value;
            var selected = municipalities_code.find(municipality => { return municipality.comune == inp.value });

            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            closeAllLists();
            showOrHidePage('hide');
            getDataBy('municipality', selected.ogc_fid, false);
            getDataBy('municipality', selected.ogc_fid, true);
          });
          a.appendChild(b);
        } else if (arr[i].code && arr[i].code.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          b.setAttribute("code", arr[i].code);
          /*make the matching letters bold:*/
          b.innerHTML = "<strong>" + arr[i].code.substr(0, val.length) + "</strong>";
          b.innerHTML += arr[i].code.substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i].code + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName("input")[0].value;
            var selected = municipalities_code.find(municipality => { return municipality.code == inp.value });

            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            closeAllLists();
            showOrHidePage('hide');
            getDataBy('pod', selected.code);
          });
          a.appendChild(b);
        }
      }
    })
    .catch(error => {
      console.error('Si è verificato un errore:', error);
    });
}

/**
 * API che recupera i POD
 */
function getPods(code, a, b, val) {
  // Definire l'URL di destinazione
  var url = `${environment.api}/pods`;

  // Eseguire la chiamata HTTP in GET utilizzando fetch
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Errore nella richiesta');
      }
      return response.json();
    })
    .then(result => {
      data = result;

      if (data) {
        if (!isAdmin) data.pods = data.pods.filter(pod => { return ['2. Produttore', '3. Prosumer (Consumatore e Produttore)'].includes(pod.type) })
        generateDataMap();
      }

    })
    .catch(error => {
      console.error('Si è verificato un errore:', error);
    });
}

/**
 * API che recupera i dati dei layer a partire dalla tipologia (codice del comune o codice del POD)
 * @param {*} entity 
 */
function getDataBy(entity, code, setLoader = false) {
  // Definire l'URL di destinazione
  var url = entity == 'municipality' ? `${environment.api}/municipalities/${code}/pods` : `${environment.api}/pods/${code}`;

  // Eseguire la chiamata HTTP in GET utilizzando fetch
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Errore nella richiesta');
      }
      return response.json();
    })
    .then(result => {
      data = result;
      if (data) {
        generateDataMap();
        if (setLoader) showOrHidePage('show');
      }
    })
    .catch(error => {
      console.error('Si è verificato un errore:', error);
    });
}

/**
 * Generazione dei componenti della mappa
 */
function generateDataMap() {
  createWKBFromData();

  const select = new Select({
  });
  map.addInteraction(select);

  // * gestione popup
  map.on('click', function (event) {
    // Prendi le feature sotto il punto cliccato
    const features = map.getFeaturesAtPixel(event.pixel);

    // Se ci sono feature, mostra il tooltip
    if (features.length > 0) {
      var clicked = features.find(feature => { return Object.keys(feature.values_).includes('entity') });

      if (clicked.values_.entity == 'pod') {
        var html = '';

        if (isAdmin) html = `<h4>${clicked.values_.code}: ${clicked.values_.name}</h4>`;
        html += `<p><b>Cabina primaria:</b> ${clicked.values_.primary_cabin}
        <br><b>Tipologia:</b> ${clicked.values_.type}
        <br><b>Ubicazione:</b> ${clicked.values_.address}
        <br><b>Destinazione:</b> ${clicked.values_.destination}`;
        if (isAdmin) html += `<br><b>Coordinate:</b> ${clicked.values_.latlon}</p>`;

        content.innerHTML = html;
      } else if (clicked.values_.entity == 'cabin') {
        content.innerHTML = `<h4>${clicked.values_.primary_cabin}: ${clicked.values_.rag_soc}</h4>`;
        // <br><b>Coordinate:</b> ${clicked.values_.latlon}</p>`;
      }

      const coordinate = event.coordinate;
      overlay.setPosition(coordinate);
    } else {
      overlay.setPosition(undefined);
    }
  });

  setupLayerVisibility('#layer', map.getLayerGroup());

  $('#layertree li > span')
    .click(function () {
      $(this).siblings('fieldset').toggle();
    })
    .siblings('fieldset')
    .hide();

  generatePODTable();
}

function autocomplete(inp) {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", function (e) {
    var a, b, i, val = this.value;
    /*close any already open lists of autocompleted values*/
    closeAllLists();
    if (!val) { return false; }
    currentFocus = -1;
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement("DIV");
    a.setAttribute("id", this.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");
    /*append the DIV element as a child of the autocomplete container:*/
    this.parentNode.appendChild(a);
    /*for each item in the array...*/

    selectedMunicipality = document.getElementById("municipality-input").value;
    getMunicipalities(selectedMunicipality, a, b, val);
  });
}

function addActive(x) {
  /*a function to classify an item as "active":*/
  if (!x) return false;
  /*start by removing the "active" class on all items:*/
  removeActive(x);
  if (currentFocus >= x.length) currentFocus = 0;
  if (currentFocus < 0) currentFocus = (x.length - 1);
  /*add class "autocomplete-active":*/
  x[currentFocus].classList.add("autocomplete-active");
}

function removeActive(x) {
  /*a function to remove the "active" class from all autocomplete items:*/
  for (var i = 0; i < x.length; i++) {
    x[i].classList.remove("autocomplete-active");
  }
}

function closeAllLists(elmnt) {
  /*close all autocomplete lists in the document,
  except the one passed as an argument:*/
  var x = document.getElementsByClassName("autocomplete-items");
  for (var i = 0; i < x.length; i++) {
    if (elmnt != x[i] && elmnt != inp) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
}

function showOrHidePage(mode) {
  // setTimeout(changeStatus, 3000);
  changeStatus();

  function changeStatus() {
    if (mode == 'show') {
      document.getElementById("loader").style.display = "none";
      document.getElementById("content").style.display = "block";
    } else {
      document.getElementById("loader").style.display = "block";
      document.getElementById("content").style.display = "none";
    }
  }
}

/*execute a function when someone clicks in the document:*/
document.addEventListener("click", function (e) {
  closeAllLists(e.target);

  var selectedPod = data.pods.find(pod => { return pod.code == e.target.id.split("-")[0] });
  if (selectedPod) {
    centerOnGeometry(selectedPod.feature, 'point', selectedPod);
  }

  if (e.target.id.includes("page")) {
    changePage(e.target.id.replace("page-", ""));
  }
});

/*execute a function presses a key on the keyboard:*/
inp.addEventListener("keydown", function (e) {
  var x = document.getElementById(this.id + "autocomplete-list");
  if (x) x = x.getElementsByTagName("div");
  if (e.keyCode == 40) {
    /*If the arrow DOWN key is pressed,
    increase the currentFocus variable:*/
    currentFocus++;
    /*and and make the current item more visible:*/
    addActive(x);
  } else if (e.keyCode == 38) { //up
    /*If the arrow UP key is pressed,
    decrease the currentFocus variable:*/
    currentFocus--;
    /*and and make the current item more visible:*/
    addActive(x);
  } else if (e.keyCode == 13) {
    /*If the ENTER key is pressed, prevent the form from being submitted,*/
    e.preventDefault();
    if (currentFocus > -1) {
      /*and simulate a click on the "active" item:*/
      if (x) {
        x[currentFocus].click();
      }
    }
  }
});

